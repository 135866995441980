
<template>
    <div>
        <div v-if="modalStatus" class="modal-layer">
            <div class="contract-modal" @click.stop>
                <slot name="oldContractNo"></slot>
                <div class="contract-modal-header">
                    <div
                        class="glyphicon glyphicon-remove float-right close-modal"
                        @click="hideModal"
                    ></div>
                    <ContractSteps
                        :step="step"
                        :total-step="totalStep"
                    ></ContractSteps>
                </div>
                <div v-show="step <= totalStep">
                    <div class="contract-modal-content">
                        <ContractFirstStep
                            v-show="step === 1"
                            ref="firstStep"
                        ></ContractFirstStep>
                        <ContractSecondStep
                            ref="secondStep"
                            :init-data-val="contractInfo"
                            :room-ids="roomIds"
                            v-show="step === 2"
                        ></ContractSecondStep>
                        <ContractThirdStep
                            v-show="step === 3"
                            ref="thirdStep"
                        ></ContractThirdStep>
                        <ContractFourthStep
                            :selected-less-id="contractInfo.lesseeId"
                            v-show="step === 4"
                            ref="fourthStep"
                        ></ContractFourthStep>
                    </div>
                </div>
                <div v-show="step > totalStep">
                    <ContractPreview
                        @change-contract-step="changeStep"
                        v-show="step === 5"
                        :contract-info="contractPreviewInfo"
                        :is-edit-preview="true"
                    ></ContractPreview>
                    <div class="contract-modal-content"></div>
                </div>
                <div class="contract-modal-footer">
                    <template v-if="step > 1">
                        <button class="btn btn-primary" @click="step--">
                            上一步
                        </button>
                    </template>
                    <template v-if="step < totalStep">
                        <button class="btn btn-primary" @click="nextStep">
                            下一步
                        </button>
                    </template>
                    <template v-if="step === totalStep + 1">
                        <button class="btn btn-primary" @click="submitContract">
                            提交
                        </button>
                    </template>
                    <template v-if="step === totalStep">
                        <button class="btn btn-primary" @click="nextStep">
                            预览并提交
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ContractSteps from "@/components/ContractSteps.vue";
import ContractFirstStep from "@/components/ContractFirstStep.vue";
import ContractSecondStep from "@/components/ContractSecondStep.vue";
import ContractThirdStep from "@/components/ContractThirdStep.vue";
import ContractFourthStep from "@/components/ContractFourthStep.vue";
import ContractPreview from "@/components/ContractPreview.vue";
import { ContractType } from "../../common/commonConst";
import {
    createContractUrl,
    preUpdateContractUrl,
    preRenewContractUrl,
} from "@/requestUrl";
import _ from "lodash";

export default {
    name: "OperateContractModal",
    props: {
        modalStatus: Boolean,
        contractType: Number,
        contractId: [String, Number],
    },
    components: {
        ContractSteps,
        ContractFirstStep,
        ContractSecondStep,
        ContractThirdStep,
        ContractFourthStep,
        ContractPreview,
    },
    data() {
        return {
            step: 1,
            totalStep: 4,
            contractPreviewInfo: {},
            lesseeCompany: "",
            roomIds: [], // 回显时使用
            rooms: [], // 预览时使用
            contractInfo: {
                regionId: this.$vc.getCurrentRegion().communityId,
                type: this.contractType,
                operatorId: this.$vc.getCurrentStaffInfo().userId,
                contractNo: "",
                startDate: "",
                endDate: "",

                installments: [],
                deposit: "",
                depositPaymentTime: "",
                rentAmount: "",
                // rentTimeunit: null,

                otherCharges: [],

                lesseeId: "",
                contractRooms: [],
                attachments: [],
                comment: "",
            },
        };
    },
    watch: {
        modalStatus(val, oldVal) {
            if (val) {
                this.initData();
                if (this.contractType != ContractType.CREATE) {
                    this.getOldContractInfo();
                }
            }
        },
    },
    methods: {
        // 初始化步骤数据
        initBaseData() {
            this.step = 1;
            this.totalStep = 4;
            this.contractPreviewInfo = {};
        },
        // 初始化弹框内所有数据
        initData() {
            this.initBaseData();
            this.lesseeCompany = "";
            this.roomIds = [];
            this.contractInfo = {
                regionId: this.$vc.getCurrentRegion().communityId,
                type: this.contractType,
                operatorId: this.$vc.getCurrentStaffInfo().userId,
                contractNo: "",
                startDate: "",
                endDate: "",

                installments: [],
                deposit: "",
                depositPaymentTime: "",
                rentAmount: "",
                // rentTimeunit: null,

                otherCharges: [],

                lesseeId: "",
                contractRooms: [],
                attachments: [],
                comment: "",
            };
        },
        hideModal() {
            this.$emit("change-modal-status", false);
        },
        // 获取旧合同内容信息
        getOldContractInfo() {
            this.$fly
                .post(preRenewContractUrl, {
                    contractId: this.contractId,
                    operatorId: this.$vc.getCurrentStaffInfo().userId,
                })
                .then((res) => {
                    this.contractPreviewInfo = res.data;
                    res.data.rooms.forEach((val) => {
                        this.roomIds.push(val.roomId);
                        this.contractInfo.contractRooms.push({
                            roomId: val.roomId,
                        });
                    });

                    this.$vc.copyObject(res.data, this.contractInfo);
                });
        },
        // 下一步
        nextStep() {
            this.getStepData();
            if (this.step === 4) {
                this.contractPreviewInfo = this.chargeRateConversion(2);
            }
            this.step++;
        },
        // 改变步骤数
        changeStep(step) {
            this.step = step;
        },
        // 验证属性是否为空
        verifyIsNull(data) {
            const notVerify = ["attachments", "comment"]; // 不需要验证的属性
            for (let key in data) {
                if (
                    data.hasOwnProperty(key) &&
                    !notVerify.includes(key) &&
                    (data[key] === undefined || data[key] === "" || data[key]?.length == 0)
                ) {
                    return true;
                }
            }
            return false;
        },
        // 获取每个步骤的数据
        getStepData() {
            const _this = this;
            const getDataMapping = {
                1: () => {
                    const data = _this.$refs.firstStep.getData();
                    if (this.verifyIsNull(data) ) {
                        this.$vc.message("请填写完当前步骤");
                        throw "请填写完当前步骤";
                        return;
                    }
                    _this.$vc.copyObject(data, _this.contractInfo);
                },
                2: () => {
                    const data = _this.$refs.secondStep.getData();
                    const {installments} = data;
                    const installmentsHasEmptyData = installments.filter(item => {
                        return item.amount === '' || item.paymentDate === '' || (item.deduction && (item.comment === '' || item.deductionAmount === ''));
                    })
                    if (this.verifyIsNull(data) || installmentsHasEmptyData.length > 0) {
                        this.$vc.message("请填写完当前步骤");
                        throw "请填写完当前步骤";
                        return;
                    }
                    this.rooms = data.selectedRooms;
                    _this.$vc.copyObject(data, _this.contractInfo);
                },
                3: () => {
                    const data = _this.$refs.thirdStep.getData();
                    /*if (this.verifyIsNull(data)) {
                        this.$vc.message("请填写完当前步骤");
                        throw "请填写完当前步骤";
                        return;
                    }*/
                    _this.$vc.copyObject(data, _this.contractInfo);
                },
                4: () => {
                    const data = _this.$refs.fourthStep.getData();
                    if (this.verifyIsNull(data)) {
                        this.$vc.message("请填写完当前步骤");
                        throw "请填写完当前步骤";
                        return;
                    }
                    this.lesseeCompany = data.lesseeCompany;
                    _this.$vc.copyObject(data, _this.contractInfo);
                },
            };
            getDataMapping[this.step]();
        },
        /**
         * 将数据中的价格全都转换为分后返回
         * @param {Number} type 1:用于数据提交  2:用于数据展示
         *  */
        chargeRateConversion(type = 1) {
            const params = _.cloneDeep(this.contractInfo);
            if (type == 2) {
                params.lesseeCompany = this.lesseeCompany;
                params.rooms = this.rooms;
            }
            params.deposit = params.deposit * 1;
            params.rentAmount = params.rentAmount * 1;
            params.type = this.contractType;
            params.installments = params.installments.map((val) => {
                val.amount = val.amount * 1;
                val.deductionAmount = val.deductionAmount * 1;
                return val;
            });
            params.otherCharges = params.otherCharges.map((charge) => {
                charge.installments = charge.installments.map((val) => {
                    val.amount = val.amount * 1;
                    val.deductionAmount = val.deductionAmount * 1;
                    return val;
                });
                return charge;
            });
            if (this.contractType != ContractType.CREATE) {
                params.pId = this.contractId;
            }
            return params;
        },
        // 提交合同
        submitContract() {
            const params = this.chargeRateConversion();
            delete params.rentAmount;
            this.$fly.post(createContractUrl, params).then((res) => {
                if (res.code != 0) {
                    return;
                }
                this.$vc.toast("操作完成");
                this.hideModal();
                this.$emit("reset-data");
            });
        },
    },
};
</script>

<style scoped>
.modal-layer {
    overflow-y: auto;
}

.contract-modal {
    /*position: absolute;*/
    width: 1200px;
    height: 800px;
    background: #ffffff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 100px auto;
    position: relative;
    /*left: 50%;
    transform: translateX(-50%);*/
}

.contract-modal-header {
    padding: 15px;
}

.contract-modal-header .close-modal {
    position: relative;
    top: var(--close-top, 0);
    font-size: 28px;
    color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.contract-modal-content {
    padding: 15px 0;
    height: calc(800px - 270px - var(--old-contract));
    overflow-y: auto;
    position: relative;
}

.contract-modal-footer {
    padding: 30px 0;
    width: 100%;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contract-modal-footer .btn {
    width: 200px;
    height: 40px;
    font-size: 24px;
    box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
    border-radius: 4px;
    padding: 0;
}

.contract-modal-footer .btn:not(:last-of-type) {
    margin-right: 50px;
}

.contract-modal .row {
    margin-left: 0;
    margin-right: 0;
}

/* 第二步代码  */
.custom-input {
    margin: 0 10px 0 15px;
}
.backgauge1 {
    margin: 0 10px 0 10px;
}
.backgauge {
    margin: 0 10px 0 0 !important;
    padding: 0;
}
.custom-input backgauge1 {
    margin-right: 10px;
}
/* .btn {
        margin-left: 5px;
    } */
.mb-1 {
    width: 800px;
    margin: 3px 5px 0 0px;
}

.mb-1 .backgauge input {
    margin: 0 10px 0 10px;
}
.mr-1 {
    margin-top: 5px;
}
.ml-1 span {
    margin-right: 10px;
}
/* .backgauge {
        padding-right: 100px;
    }
    */

.step-component .el-input__inner {
    font-size: 24px;
}

@media screen and (max-width: 1400px) {
    .contract-modal {
        transform: scale(0.8);
        margin: calc(100px * 0.4) auto;
    }
}

</style>
